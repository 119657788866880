import { ReactNode } from 'react';

import { FormUsageContext } from 'components/Form/Form';
import { StyledNativeLinkWithRef } from 'components/Link/StyledNativeLinkWithRef';
import { StyledVersionedRouterLink } from 'components/Link/StyledVersionedRouterLink';
import { trackClicked } from 'utils/analytics/track/trackClicked';

type Props = {
  formUsageContext: FormUsageContext;
  children: ReactNode;
  email: string;
  signUpOverrideFn?: () => void;
  showSignupModal: (email: string) => void;
  getSignupUrl: (email: string) => {
    pathname: string;
    state: Record<string, unknown>;
  };
};

export function LoginFormSignupLink({
  formUsageContext,
  children,
  email,
  signUpOverrideFn,
  showSignupModal,
  getSignupUrl,
}: Props) {
  if (signUpOverrideFn) {
    return (
      <StyledNativeLinkWithRef
        href="#"
        data-qa-id="login-form-signup-link"
        onClick={(e) => {
          e.preventDefault();
          trackClicked('Sign Up');
          signUpOverrideFn();
        }}
      >
        {children}
      </StyledNativeLinkWithRef>
    );
  }

  if (formUsageContext === 'modal') {
    return (
      <StyledNativeLinkWithRef
        href="#"
        data-qa-id="login-form-signup-link"
        onClick={(e) => {
          e.preventDefault();
          trackClicked('Sign Up');
          showSignupModal(email);
        }}
      >
        {children}
      </StyledNativeLinkWithRef>
    );
  }

  const url = getSignupUrl(email);

  return (
    <StyledVersionedRouterLink
      to={url.pathname}
      state={url.state}
      data-qa-id="login-form-signup-link"
      onClick={() => {
        trackClicked('Sign Up');
      }}
    >
      {children}
    </StyledVersionedRouterLink>
  );
}
