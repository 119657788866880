import { ComponentType } from 'react';
import {
  Location,
  NavigateFunction,
  NavigationType,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom';

// This implementation of withRouter is there only to upgrade
// react-router, since they stopped providing it and only the
// hooks available now. We needed it to support class based
// components that have not been re-written yet.
export function withRouter<TLocationState, TComponentProps>(
  Component: ComponentType<
    TComponentProps & RouteComponentProps<TLocationState | null>
  >,
): ComponentType<
  Omit<TComponentProps, 'location' | 'navigate' | 'navigationType'>
> {
  function ComponentWithRouterProp(props: TComponentProps) {
    const location = useLocation<TLocationState>();
    const navigate = useNavigate();
    const navigationType = useNavigationType();

    return (
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        location={location}
        navigate={navigate}
        navigationType={navigationType}
      />
    );
  }

  // @ts-expect-error fix this related to forcefully Omit route properties
  return ComponentWithRouterProp;
}

export type RouteComponentProps<TLocationState = unknown> = {
  location: Location<TLocationState>;
  navigate: NavigateFunction;
  navigationType: NavigationType;
};
