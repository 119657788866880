import { ReactNode } from 'react';

import { FormUsageContext } from 'components/Form/Form';
import { StyledNativeLinkWithRef } from 'components/Link/StyledNativeLinkWithRef';
import { StyledVersionedRouterLink } from 'components/Link/StyledVersionedRouterLink';

type Props = {
  formUsageContext: FormUsageContext;
  children: ReactNode;
  email: string;
  showForgotPasswordModal: (data: { email: string }) => void;
  getForgotPasswordUrl: (data: { email: string }) => {
    pathname: string;
    state: Record<string, unknown>;
  };
};

export function LoginFormForgotPasswordLink({
  formUsageContext,
  children,
  email,
  showForgotPasswordModal,
  getForgotPasswordUrl,
}: Props) {
  if (formUsageContext === 'modal') {
    return (
      <StyledNativeLinkWithRef
        href="#"
        onClick={(e) => {
          e.preventDefault();
          showForgotPasswordModal({
            email,
          });
        }}
      >
        {children}
      </StyledNativeLinkWithRef>
    );
  }

  const url = getForgotPasswordUrl({ email });

  return (
    <StyledVersionedRouterLink to={url.pathname} state={url.state}>
      {children}
    </StyledVersionedRouterLink>
  );
}
